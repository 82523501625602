
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddBalanceModal from "@/components/modals/forms/AddBalanceModal.vue"
import AddPointModal from "@/components/modals/forms/AddPointModal.vue"
import { BoxCategory } from "@/store/modules/BoxCategoryModule"

export default defineComponent({
  components: {
    AddBalanceModal,
    AddPointModal
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("非酋排名列表", ["非酋排名管理;loser_items"])
    })

    const store = useStore()
    const router = useRouter()
    const tableData = ref([])
    const currentMemberId = ref(0)

    const setCurrentMemberId = (id) => {
      currentMemberId.value = id
    }

    const formData = ref({
      nickname: "",
      mobile: "",
      type: '',
      category_id: ''
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    let date = new Date()
    let from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    let to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)

    const duration = ref([
      from,
      to,
    ])

    const query = () => {
      let params = {
        ...formData.value,
        from: `${duration.value[0].toLocaleDateString()} ${duration.value[0].getHours()}:${duration.value[0].getMinutes()}:${duration.value[0].getSeconds()}`,
        to: `${duration.value[1].toLocaleDateString()} ${duration.value[1].getHours()}:${duration.value[1].getMinutes()}:${duration.value[1].getSeconds()}`,
      }

      store
        .dispatch(Actions.GET_LOSER_ITEMS, { ...params, ...page.value })
        .then(() => {
          tableData.value = store.getters.currentLoserItems
          page.value.totalResult = store.getters.currentLoserItemCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query();

    const submitEvent = () => {
      query();
    };

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage;
      query();
    };

    const onReset = () => {
      formData.value = {
        nickname: "",
        mobile: "",
        type: '',
        category_id: ''
      };
    }

    const box_categories = ref(Array<BoxCategory>())

    store
      .dispatch(Actions.GET_BOX_CATEGORIES)
      .then(() => {
        box_categories.value = store.getters.currentBoxCategories
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            minute = '' + d.getMinutes(),
            second = '' + d.getSeconds()

        if (month.length < 2) 
            month = '0' + month
        if (day.length < 2) 
            day = '0' + day
        if (hour.length < 2) 
            hour = '0' + hour
        if (minute.length < 2) 
            minute = '0' + minute
        if (second.length < 2) 
            second = '0' + second

        return [year, month, day, hour, minute, second].join('')
    }

    const onDownload = () => {
      let params = {
        ...formData.value,
        from: `${duration.value[0].toLocaleDateString()} ${duration.value[0].getHours()}:${duration.value[0].getMinutes()}:${duration.value[0].getSeconds()}`,
        to: `${duration.value[1].toLocaleDateString()} ${duration.value[1].getHours()}:${duration.value[1].getMinutes()}:${duration.value[1].getSeconds()}`,
      }

      store
        .dispatch(Actions.GET_DOWNLOAD_LOSER_ITEMS, params)
        .then(() => {
          const blob = new Blob([store.getters.currentDownloadLoserItems], { type: "application/xlsx" });
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = `loser_items_${formatDate(Date().toString())}.xlsx`
          link.click();
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      tableData,
      formData,
      submitEvent,
      page,
      onPageChange,
      onReset,
      duration,
      box_categories,
      onDownload
    }
  },
})
